@font-face {
  font-family: 'ico-blink';
  src: url('/assets/fonts/ico-blink/ico-blink.eot?54779841');
  src: url('/assets/fonts/ico-blink/ico-blink.eot?54779841#iefix') format('embedded-opentype'),
  url('/assets/fonts/ico-blink/ico-blink.woff2?54779841') format('woff2'),
  url('/assets/fonts/ico-blink/ico-blink.woff?54779841') format('woff'),
  url('/assets/fonts/ico-blink/ico-blink.ttf?54779841') format('truetype'),
  url('/assets/fonts/ico-blink/ico-blink.svg?54779841#ico-blink') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-blink-"]:before, [class*="ico-blink-"]:before {
  font-family: "ico-blink";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.ico-blink-sun:before { content: '\e802'; } /* '' */
.ico-blink-moon:before { content: '\e803'; } /* '' */
.ico-blink-morn-sun:before { content: '\e804'; } /* '' */
