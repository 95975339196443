@media screen and (min-width: 1025px) {
  .fixed-top-desktop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .hide-desktop {
    display: none;
  }
  .padding-left-right-13 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
  bw-header {
    nav img {
      display: block;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .has-error .error-text {
    bottom: -27px !important;
  }
}

@media (min-width: 1025px) {
  $sidebar-width: 16.66666667%;
  $layout-width: 83.33333333%;
  .bw-col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 $sidebar-width;
    flex: 0 0 $sidebar-width;
    max-width: $sidebar-width;
  }
  .bw-col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 $layout-width;
    flex: 0 0 $layout-width;
    max-width: $layout-width;
  }
}

@media (min-width: 1200px) {
  $sidebar-width: 350px;
  $layout-width: calc(100% - 350px);
  .bw-col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 $sidebar-width;
    flex: 0 0 $sidebar-width;
    max-width: $sidebar-width;
  }
  .bw-col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 $layout-width;
    flex: 0 0 $layout-width;
    max-width: $layout-width;
  }
}
