@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// After angular 15, we also need to import legacy styles until we update our mat components
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-light-blue-blink: (
  50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03A6DB,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$blink-app-primary: mat-palette($mat-light-blue-blink);
$blink-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$blink-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$blink-app-theme: mat.define-light-theme(
(
        color: (
          primary: $blink-app-primary,
          accent: $blink-app-accent,
          warn: $blink-app-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($blink-app-theme);

//@import 'bootstrap/scss/bootstrap';
//@import './scss/blink-theme/default/style';
@import "./scss/blink/style";
// @import './scss/custom';

.ng-invalid:not(form, div) {
  border-left: none;
}

.ng-valid[required],
.ng-valid.required {
  border-left: none;
}

.clickeable {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.screenreader-only {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.mat-mdc-form-field {
  @include mat.form-field-density(-1);
}

/* An update in Bootstrap 5 removed all position:relative in col-* classes. This css is to bring it back to fix the dependent classes */
[class*='col'] {
  position: relative;
}

.pull-right {
  float: right;
}