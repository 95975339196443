//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
@use 'sass:math';

:root {
  --primary-color-orange: #F16533;
  --primary-color-orange-2: #FF5100;
  --primary-color-orange-3: #ED5D2A;
  --primary-color-orange-4: #fc6127;
  --primary-color-orange-light: #FB7F64;

  --primary-color-green-2: #00A676;
  --primary-color-green-3: #B3B3B3;
  --primary-color-green-4: #61BF24;
  --primary-color-green-5: #D6F763;
}

$sans-serif-family: 'BlinkFitness_Lean', 'Helvetica Neue', Arial, sans-serif;
$font-family-bold: 'BlinkFitness_Strong', 'Helvetica Neue', Arial, sans-serif;
$font-family-balanced: 'BlinkFitness_Balanced', 'Helvetica Neue', Arial, sans-serif;
$font-family-Roboto: Roboto, 'Helvetica Neue', sans-serif;

$ico-blink-font: 'ico-blink';

$primary-color-red: #EE3F00;
$primary-color-red-2: #F52900;
$primary-color-red-3: #FF0000;
$primary-color-orange: var(--primary-color-orange);
$primary-color-orange-2: var(--primary-color-orange-2);
$primary-color-orange-3: var(--primary-color-orange-3);
$primary-color-orange-4: var(--primary-color-orange-4);
$primary-color-orange-light: var(--primary-color-orange-light);
$primary-color-blue: #03A6DB;
$primary-color-blue-2: #095CD3;
$primary-color-blue-3: #095DD3;
$primary-color-blue-light: #7CCBED;
$primary-color-blue-dark: #1D6EDB;
$primary-color-blue-dark2: #0277bd;
$primary-color-blue-dark3: #01579b;
$primary-color-green: #BFD530;
$primary-color-green-2: var(--primary-color-green-2);
$primary-color-green-3: var(--primary-color-green-3);
$primary-color-green-4: var(--primary-color-green-4);
$primary-color-green-5: var(--primary-color-green-5);
$primary-color-navy: #445361;
$primary-color-papaya: #EF4836;
$primary-color-yellow: #FFE400;
$primary-color-light-gray: #ecedef;
$primary-color-warm-grey: #d7d7d7;
$primary-color-warm-grey-2: #d5d5d5;
$primary-color-warm-grey-3: #b1b6ba;
$primary-color-dark-grey: #b1b0b1;
$primary-color-grey: #777;
$primary-color-grey-2: #F6F6F6;
$primary-color-grey-3: #4A4F54;
$primary-color-grey-4: #4b4f53;
$primary-color-grey-5: #969696;
$primary-color-grey-6: #63696e;

$content-max-size: 1920px !default;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$black-color: #000000 !default;
$white-color: #FFFFFF !default;
$white-bg: #FFFFFF !default;
$orange-bg: #e95e38 !default;

$smoke-bg: #F5F5F5 !default;
$light-black: #444 !default;

$black-bg: rgba(30, 30, 30, .97) !default;

$black-color: #2c2c2c !default;
$black-hr: #444444 !default;

$light-gray: #E3E3E3 !default;
$light-gray-1: #E5E5E5 !default;
$medium-gray: #DDDDDD !default;
$dark-gray: #9A9A9A !default;

$opacity-gray-1: rgba(229, 229, 229, 0.1) !default;
$opacity-gray-3: rgba(222, 222, 222, .3) !default;
$opacity-gray-5: rgba(222, 222, 222, .5) !default;
$opacity-gray-8: rgba(222, 222, 222, .8) !default;

$opacity-5: rgba(255, 255, 255, .5) !default;
$opacity-8: rgba(255, 255, 255, .8) !default;

$datepicker-color-days: rgba(255, 255, 255, .8) !default;
$datepicker-color-old-new-days: rgba(255, 255, 255, .4) !default;

$opacity-1: rgba(255, 255, 255, .1) !default;
$opacity-2: rgba(255, 255, 255, .2) !default;

$transparent-bg: transparent !default;
$dark-background: #555555 !default;

$default-color: #888 !default;
$default-states-color: lighten($default-color, 6%) !default;
$default-color-opacity: rgba(182, 182, 182, .6) !default;

$primary-color: #f96332 !default;
$primary-states-color: lighten($primary-color, 6%) !default;
$primary-color-opacity: rgba(249, 99, 50, .3) !default;
$primary-color-alert: rgba(249, 99, 50, .8) !default;

$success-color: #18ce0f !default;
$success-states-color: lighten($success-color, 6%) !default;
$success-color-opacity: rgba(24, 206, 15, .3) !default;
$success-color-alert: rgba(24, 206, 15, .8) !default;

$info-color: #2CA8FF !default;
$info-states-color: lighten($info-color, 6%) !default;
$info-color-opacity: rgba(44, 168, 255, .3) !default;
$info-color-alert: rgba(44, 168, 255, .8) !default;

$warning-color: #FFB236 !default;
$warning-states-color: lighten($warning-color, 6%) !default;
$warning-color-opacity: rgba(255, 178, 54, .3) !default;
$warning-color-alert: rgba(255, 178, 54, .8) !default;

$danger-color: #FF3636 !default;
$danger-states-color: lighten($danger-color, 6%) !default;
$danger-color-opacity: rgba(255, 54, 54, .3) !default;
$danger-color-alert: rgba(255, 54, 54, .8) !default;
$error-color-alert: rgba(205, 13, 13, 0.8) !default;

/*     brand Colors              */
$brand-primary: $primary-color !default;
$brand-info: $info-color !default;
$brand-success: $success-color !default;
$brand-warning: $warning-color !default;
$brand-danger: $danger-color !default;
$brand-inverse: $black-color !default;
$brand-orange: $primary-color-orange !default;

$link-disabled-color: #666666 !default;

/*      light colors         */
$light-blue: rgba($primary-color, .2);
$light-azure: rgba($info-color, .2);
$light-green: rgba($success-color, .2);
$light-orange: rgba($warning-color, .2);
$light-red: rgba($danger-color, .2);
$light-navy: lighten($primary-color-navy, 30%);
$light-yellow: lighten($primary-color-yellow, 40%);

//== Components
//

$promo-banner-height: 58px;

$padding-input-vertical: 11px !default;
$padding-input-horizontal: 19px !default;

$padding-btn-vertical: 11px !default;
$padding-btn-horizontal: 22px !default;

$padding-container-desktop: 30px !default;
$padding-container-mobile: 20px !default;

$padding-base-vertical: .5rem !default;
$padding-base-horizontal: .7rem !default;

$padding-round-horizontal: 23px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 17px !default;

$padding-large-vertical: 15px !default;
$padding-large-horizontal: 48px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 15px !default;

// $padding-xs-vertical:           1px !default;
// $padding-xs-horizontal:         5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;
$margin-small-vertical: 10px !default;

$margin-base-horizontal: 15px !default;

$margin-bottom: 10px !default;
$border: 1px solid !default;
$border-radius-extra-small: 0.125rem !default;
$border-radius-small: 0.1875rem !default;
$border-radius-large: 0.25rem !default;
$border-radius-extreme: 0.875rem !default;

$border-radius-large-top: $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius: 3px !default;
$btn-round-radius-oval: 25px !default;

$height-base: 55px !default;

$btn-icon-size: 3.5rem !default;
$btn-icon-size-regular: 2.375rem !default;
$btn-icon-font-size-regular: 0.9375rem !default;
$btn-icon-font-size-small: 0.6875rem !default;
$btn-icon-size-small: 1.875rem !default;
$btn-icon-font-size-lg: 1.325rem !default;
$btn-icon-size-lg: 3.6rem !default;

$font-size-h1: 3.75em !default; // ~ 60px
$font-size-h2: 2.5em !default; // ~ 35px
$font-size-h3: 2em !default; // ~ 28px
$font-size-h4: 1.714em !default; // ~ 24px
$font-size-h5: 1.5em !default; // ~ 24px
$font-size-h6: 1.1em !default; // ~ 16px

$font-paragraph: 1em !default;
$font-size-navbar: 1em !default;
$font-size-mini: 0.7142em !default;
$font-size-small: 0.8571em !default;
$font-size-base: 14px !default;
$font-size-large: 1em !default;
$font-size-large-navbar: 20px !default;
$font-size-blockquote: 1.1em !default; // ~ 15px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 600 !default;
$font-weight-bold: 700 !default;

$line-height-general: 1.5 !default;
$line-height-nav-link: 1.625rem !default;
$btn-icon-line-height: 2.4em !default;
$line-height: 1.35em !default;
$line-height-lg: 54px !default;

$border-radius-top: 10px 10px 0 0 !default;
$border-radius-bottom: 0 0 10px 10px !default;

$dropdown-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow-grey-color: rgba(214, 214, 214, 0.29);

$general-transition-time: 300ms !default;

$slow-transition-time: 370ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;
$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$navbar-height-desktop: 96px;
$navbar-height-mobile: 62px;

$navbar-size: 100vw;
$navbar-tablet-max-size: 420px;
$navbar-margin-a: 15px 0px;

$padding-social-a: 10px 5px;

$navbar-margin-a-btn: 15px 0px;
$navbar-margin-a-btn-round: 16px 0px;

$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;

$navbar-padding-base: 0.625rem;

$navbar-margin-brand-icons: 12px auto;
$navbar-margin-btn: 15px 3px;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$white-navbar: rgba(#FFFFFF, .96);
$primary-navbar: rgba(#34ACDC, .98);
$info-navbar: rgba(#5BCAFF, .98);
$success-navbar: rgba(#4CD964, .98);
$warning-navbar: rgba(#FF9500, .98);
$danger-navbar: rgba(#FF4C40, .98);

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

//Nucleo Icons

$nc-font-path: '../fonts' !default;
$nc-font-size-base: 14px !default;
$nc-css-prefix: now-ui !default;
$nc-background-color: #eee !default;
$nc-li-width: math.div(30em, 14) !default;
$nc-padding-width: math.div(1em, 3) !default;

// Social icons color
$social-twitter: #55acee !default;
$social-twitter-state-color: darken(#55acee, 5%) !default;

$social-facebook: #3b5998 !default;
$social-facebook-state-color: darken(#3b5998, 5%) !default;

$social-google: #dd4b39 !default;
$social-google-state-color: darken(#dd4b39, 5%) !default;

$social-linkedin: #0077B5 !default;
$social-linkedin-state-color: darken(#0077B5, 5%) !default;

$green-success: #ccede5;

$gray1: #B3B3B3;
$gray2: #4A4F54;
$gray3: #b1b6ba;
$gray4: #445361;
$gray5: #acb9c5;
$gray6: #8a8888;
$gray7: #cac8ca;
$gray8: #36424d;
$gray9: #b4bac0;
$gray10: #b1b1b1;
$gray11: #8c8c93;
$gray12: #2c2c2c;
$gray13: #E4E5E4;
$blue1: #095CD3;
$blue2: #428bca;
$blue3: #2a6496;
$blue4: #1d6edb;
$blue5: #1486ad;
$blue6: #007bff;
$white1: #F0F0F0;
$white2: #f6e8de;
$white3: #efefef;
$green1: #91a221;
$green2: #9caf23;
$green3: #008F00;
$green4: #869726;
$orange1: #e2470f;
$orange2: #eb4a10;
$orange3: #d3420e;
$orange4: #ef4836;
$orange5: #f36327;
$orange6: #e54600;
$black1: #190a0a;
$red1: #ff0000;
$opacity-black1: rgba(0, 0, 0, 0.6);
$opacity-black2: rgba(0, 0, 0, 0.3);
$opacity-black3: rgba(0, 0, 0, 0.7);
$opacity-gray1: rgb(44, 44, 44);
$opacity-gray2: rgba(255, 255, 255, 0.7);
$opacity-gray3: rgba(255, 255, 255, 0);
$opacity-gray4: rgba(199, 199, 199, 0.7);
