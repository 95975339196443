@font-face {
  font-family: 'BlinkFitness_Balanced';
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.eot'); /* IE9 Compat Modes */
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_balanced-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'BlinkFitness_Lean';
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.eot'); /* IE9 Compat Modes */
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_lean-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'BlinkFitness_Strong';
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.eot'); /* IE9 Compat Modes */
  src: url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../../assets/fonts/blink-new-font/blinkfitness_strong-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap
}

.BlinkFitness_Strong {
  font-family: 'BlinkFitness_Strong', sans-serif;
}

.BlinkFitness_Lean {
  font-family: 'BlinkFitness_Lean', sans-serif;
}

.BlinkFitness_Balanced {
  font-family: 'BlinkFitness_Balanced', sans-serif;
}

.text-red, .text-red:hover, .text-red:focus {
  color: $primary-color-red;
}

.text-orange, .text-orange:hover, .text-orange:focus {
  color: $primary-color-orange;
}

.text-orange-light, .text-orange-light:hover, .text-orange-light:focus {
  color: $primary-color-orange-light;
}

.text-blue, .text-blue:hover, .text-blue:focus {
  color: $primary-color-blue;
}

.text-green, .text-green:hover, .text-green:focus {
  color: $primary-color-green;
}

.text-navy, .text-navy:hover, .text-navy:focus {
  color: $primary-color-navy;
}

.text-light-grey, .text-light-grey:hover, .text-light-grey:focus {
  color: $primary-color-light-gray;
}

.text-warm-grey, .text-warm-grey:hover, .text-warm-grey:focus {
  color: $primary-color-warm-grey;
}

.text-dark-grey, .text-dark-grey:hover, .text-dark-grey:focus {
  color: $primary-color-dark-grey;
}

.text-grey, .text-grey:hover, .text-grey:focus {
  color: $primary-color-grey;
}

.text-white, .text-white:hover {
  color: $white-color;
}

.border-bottom-warm-grey {
  border-bottom: 1px solid $primary-color-warm-grey;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $sans-serif-family;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-normal;
}

small {
  font-size: 60%;
}

a {
  color: $primary-color-navy;

  &:hover,
  &:focus {
    color: $primary-color-navy;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: .8;
  }
}

h2, .h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}

h3, .h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}

h4, .h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}

h5, .h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6, .h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
}

p {
  font-size: 0.875em;
  line-height: 1.125em;

  .description &,
  &.description {
    font-size: 1.14em;
  }
}

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }

  & + .category {
    margin-top: -25px;
  }
}

.description,
.card-description,
.footer-big p {
  color: $dark-gray;
  font-weight: $font-weight-light;
}

.category {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $dark-gray;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;

  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &.blockquote-primary {
    border-color: $primary-color;
    color: $primary-color;

    small {
      color: $primary-color;
    }
  }

  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;

    small {
      color: $danger-color;
    }
  }

  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;

    small {
      color: $opacity-8;
    }
  }
}

@import "./Blink-icon";
