@import 'bootstrap/scss/bootstrap';
@import "~@adyen/adyen-web/dist/adyen.css";
@import "framework/base";
// Blink Fitness Theme Colors
.bg-red {
  background-color: $primary-color-red;
}

.bg-orange {
  background-color: $primary-color-orange;
}

.bg-orange-light {
  background-color: $primary-color-orange-light;
}

.bg-blue {
  background-color: $primary-color-blue;
}

.bg-green {
  background-color: $primary-color-green;
}

.bg-navy {
  background-color: $primary-color-navy;
}

.bg-light-gray {
  background-color: $primary-color-light-gray;
}

.bg-warm-grey {
  background-color: $primary-color-warm-grey;
}

.bg-warm-grey-2 {
  background-color: $primary-color-warm-grey-2;
}

.bg-dark-grey {
  background-color: $primary-color-dark-grey;
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42A948;
  /* green */
}

.ng-invalid:not(form, div) {
  border-left: 5px solid #a94442;
  /* red */
}

.screenreader-only {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

