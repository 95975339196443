
.text-red, .text-red:hover, .text-red:focus {
  color: $primary-color-red;
}

.text-primary, .text-primary:hover, .text-primary:focus {
  color: var(--primary-color) !important;
}

.text-primary-light, .text-primary-light:hover, .text-primary-light:focus {
  color: var(--primary-color-lighter)  !important;
}

.text-secondary, .text-secondary:hover, .text-secondary:focus {
  color: var(--secondary-color) !important;
}

.text-tertiary, .text-tertiary:hover, .text-tertiary:focus {
  color: var(--tertiary-color) !important;
}

.text-navy, .text-navy:hover, .text-navy:focus {
  color: $primary-color-navy;
}

.text-light-grey, .text-light-grey:hover, .text-light-grey:focus {
  color: $primary-color-light-gray;
}

.text-warm-grey, .text-warm-grey:hover, .text-warm-grey:focus {
  color: $primary-color-warm-grey;
}

.text-dark-grey, .text-dark-grey:hover, .text-dark-grey:focus {
  color: $primary-color-dark-grey;
}

.text-grey, .text-grey:hover, .text-grey:focus {
  color: $primary-color-grey;
}

.text-white, .text-white:hover {
  color: $white-color;
}

.border-bottom-warm-grey {
  border-bottom: 1px solid $primary-color-warm-grey;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-lean;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-normal;
}

small {
  font-size: 60%;
}

a {
  color: $primary-color-navy;

  &:hover,
  &:focus {
    color: $primary-color-navy;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: .8;
  }
}

h2, .h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}

h3, .h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}

h4, .h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}

h5, .h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6, .h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
}

p {
  font-size: 0.875em;
  line-height: 1.125em;

  .description &,
  &.description {
    font-size: 1.14em;
  }
}

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }

  & + .category {
    margin-top: -25px;
  }
}

.description,
.card-description,
.footer-big p {
  color: $dark-gray;
  font-weight: $font-weight-light;
}

.category {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $dark-gray;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;

  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &.blockquote-primary {
    border-color: var(--primary-color);
    color: var(--primary-color);

    small {
      color: var(--primary-color);
    }
  }

  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;

    small {
      color: $danger-color;
    }
  }

  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;

    small {
      color: $opacity-8;
    }
  }
}

@import "./Blink-icon";
